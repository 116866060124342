<template>
	<div class="container-fluid animatedParent animateOnce my-3">
		<!-- main content -->
		<div class="tab-content">
			<div class="tab-pane animated fadeInUpShort show active go">
				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">

								<div class="row">
									<div class="col-md-12">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											<!-- <input v-model="query" class="form-control p-2" placeholder="搜索" type="text"> -->

											<!-- <div class="input-group">
												<input v-model="start_date" id="date_timepicker_start" type="text" class="date-time-picker form-control" style="width: 105px;">
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->
											<date-picker v-model="start_date" format="YYYY-MM-DD" value-type="format"
												input-class="form-control" :editable="false" :clearable="false">
											</date-picker>

											<!-- <div class="input-group ml-2">
												<input v-model="end_date" id="date_timepicker_end" type="text" class="date-time-picker form-control" style="width: 105px;" >
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->

											<div class="ml-2">
												<date-picker v-model="end_date" format="YYYY-MM-DD" value-type="format"
													input-class="form-control" :editable="false" :clearable="false">
												</date-picker>
											</div>

											<!-- 食堂特有 -->
											<span v-if="outlet_level==1">
												<select v-model="employee_id" class="form-control  ml-2">
													<option value="0">所有收银员</option>
													<option v-bind:key="index" 
														:value="item.id" v-for="(item,index) in activeEmployees">
														{{item.employee_name}}</option>
												</select>
											</span>
											<select v-model="order_type" class="form-control ml-2">
												<!-- 食堂使用 -->
												<option value="0">所有点餐方式</option>
												<option v-if="outlet_level==1" value="1">窗口</option><!-- 新的点餐方式，切记 -->
												<option v-if="outlet_level==1" value="5">自助</option><!-- 自助扫码点餐默认为到店 -->
												<!-- 扫码点餐使用 -->
												<option v-if="outlet_level==0" value="1">到店</option>
												<option v-if="outlet_level==0" value="5">外卖</option>
											</select>


											<select v-model="payment" class="form-control ml-2">
												<option value="">所有支付方式</option>
												<option value="BALANCE">会员卡</option>
												<option value="WEIXIN">微信支付</option>
												<option value="CASH" v-if="outlet_level==1">现金</option>
											</select>

											<span v-if="outlet_level==0">
												<select v-model="trade_status" class="form-control ml-2" v-show="true">
													<option value="-9">所有状态</option>
													<option value="0">待付款</option>
													<option value="1">已付款</option>
													<option value="-1">已取消</option>
												</select>
											</span>

											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i
													class="icon-search"></i>
											</button>
										</div>
										<!-- 搜索 end -->
									</div>
									<!-- <div class="col-md-3">
										<div class="float-right m-3">
											<button type="button" class="btn btn-secondary">导出</button>
										</div>
									</div> -->
								</div>
							</div>

							<div class="table-responsive">
								<div v-if="saleorderlist.length > 0">
									<table class="table table-striped table-hover r-0  mb-0">
										<thead>
											<tr class="no-b">
												<th style="width: 60px">
													<!-- <div class="custom-control custom-checkbox">
														<input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
													</div> -->
													序号
												</th>
												<th>时间</th>
												<th>
													<div class="d-none d-lg-block">单号</div>
												</th>
												<th v-show="outlet_level==0">
													<div class="d-none d-lg-block">取餐号</div>
												</th>
												<th>
													<div class="d-none d-lg-block">卡号</div>
												</th>
												<th>
													<div class="d-none d-lg-block">金额(元)</div>
												</th>

												<th v-show="outlet_level==0">
													<div class="d-none d-lg-block">状态</div>
												</th>
												<th>
													<div class="d-none d-lg-block">支付方式</div>
												</th>
												<th>
													<div class="d-none d-lg-block">点餐方式</div>
												</th>
												<th v-if="outlet_level==1">
													<div class="d-none d-lg-block">收银员</div>
												</th>
												<th></th>
											</tr>
										</thead>

										<tbody>
											<tr v-bind:key="index" v-for="(item,index) in saleorderlist"
												:class="{'blue lighten-5':selectedIndex == index}">
												<td>
													{{index+1}}
													<!-- <label class="custom-control custom-checkbox">
														<input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
													</label> -->
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.sale_date}}</div>
												</td>

												<td>
													<div class="d-none d-lg-block">{{item.sale_order_no}}</div>
												</td>

												<td v-show="outlet_level==0">
													<div class="d-none d-lg-block">
														{{item.serial_no}}
													</div>
												</td>

												<td>
													<div class="d-none d-lg-block">{{item.customer_code}}</div>
												</td>

												<td>
													<div class="d-none d-lg-block">
														<span class="r-3  font-weight-bold">{{item.total_amount}}</span>
														<span v-if="item.refund_amount>0" class="d-lg-block text-warning">退款{{item.refund_amount}}</span>
													</div>
												</td>

												<td v-show="outlet_level==0">
													<div class="d-none d-lg-block">
														<!-- {{item.pay_status}} | {{item.trade_status}} -->
														<span
															:class="[ 's-12  mr-2',{'text-success' : item.trade_status == 3, 'text-info' : item.trade_status == 1, 'text-aqua':item.trade_status == 0, 'text-dark':item.trade_status == -1, 'text-warning':item.trade_status == 2}]">
															{{item.tradeStatusDesc}}
														</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.paymentDesc}}</div>
												</td>
												<td>
													<div v-if="outlet_level==1" class="d-none d-lg-block">
														{{item.order_type==1?'窗口':'自助'}}</div>
													<div v-if="outlet_level==0" class="d-none d-lg-block">
														{{item.order_type==1?'到店':'外卖'}}</div>
												</td>
												<td v-if="outlet_level==1">
													<div class="d-none d-lg-block">{{item.employee_id}}</div>
												</td>
												<td>
													<button @click.stop="view(index)" type="button"
														class="btn btn-outline-primary btn-xs"> 详情 </button>
													<button
														v-if="outlet_level==0 && item.order_type==5 && item.trade_status > 0 && item.trade_status < 3"
														@click.stop="deliveryReady(index)" type="button"
														class="btn btn-outline-primary btn-xs ml-2"> 配送 </button>
												</td>
											</tr>


										</tbody>
										<tfoot>
											<tr class="no-b">
												<th>

												</th>
												<th>小计</th>
												<th></th>
												<th v-show="outlet_level==0"></th>
												<th></th>
												<th>
													<div class="d-none d-lg-block">{{subtotalAmount}}</div>
												</th>
												<th colspan="4"></th>
											</tr>
											<tr class="no-b">
												<th>
												</th>
												<th>合计</th>
												<th></th>
												<th v-show="outlet_level==0"></th>
												<th></th>
												<th>
													<div class="d-none d-lg-block">{{totalAmount}}</div>
												</th>
												<th colspan="4"></th>
											</tr>
										</tfoot>
									</table>
								</div>
								<div v-if="saleorderlist.length == 0">
									<div class="card-body text-lg-center m-5 height-300">
										<h5>没有消费订单</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row" v-if="saleorderlist.length > 0">
					<div class="col-sm-12 col-md-5">
						<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
							显示 {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
						</div>
					</div>
					<div class="col-sm-12 col-md-7">

						<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>

					</div>
				</div>

			</div>

		</div>
		<!-- //main content -->

		<!-- modal -->
		<!-- 订单详情 modal -->
		<div class="modal fade" id="hModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">订单详情</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-0">
							<div class="invoice white shadow">
								<div class="p-2">
									<!-- title row -->
									<div class="row">
										<div class="col-6">
											<p class="lead text-danger font-weight-bold">
												{{saleorder.tradeStatusDesc}}
											</p>
										</div>
										<div class="col-6">
											<!-- <img class="w-80px mb-4" src="assets/img/dummy/bootstrap-social-logo.png" alt=""> -->
											<div class="float-right">
												<h4>取餐号 #{{saleorder.serial_no}} 
												<span v-if="outlet_level==1" class="badge badge-pill badge-primary s-12">{{saleorder.order_type==1?'窗口':'自助'}}</span>
												<span v-if="outlet_level==0" class="badge badge-pill badge-primary s-12">{{saleorder.order_type==1?'到店':'外卖'}}</span>
												</h4>
												<table>
													<tbody>
														<tr>
															<td class="font-weight-normal">订单号:</td>
															<td>{{saleorder.sale_order_no}}</td>
														</tr>
														<tr>
															<td class="font-weight-normal">消费日期:</td>
															<td>{{saleorder.sale_date}}</td>
														</tr>
														<tr>
															<td class="font-weight-normal">会员卡号:</td>
															<td>{{saleorder.customer_code}}</td>
														</tr>
														<!-- <tr>
					                                    <td class="font-weight-normal">支付方式:</td>
					                                    <td> {{saleorder.payment}}</td>
					                                </tr> -->
														<!-- <tr>
					                                    <td class="font-weight-normal">点餐方式:</td>
					                                    <td>{{saleorder.order_type}}</td>
					                                </tr> -->
													</tbody>
												</table>

											</div>

										</div>
										<!-- /.col -->
									</div>

									<!-- Table row -->
									<div class="row my-3">
										<div class="col-12 table-responsive">
											<table class="table table-striped table-sm">
												<thead>
													<tr>
														<th>数量</th>
														<th>商品</th>
														<th>单价(元)</th>
														<th>金额(元)</th>
													</tr>
												</thead>
												<tbody>
													<tr v-bind:key="index" v-for="(item,index) in saleorderitemlist">
														<td>{{item.qty}}</td>
														<td>{{item.product_name}}</td>
														<td>{{item.price}}/{{item.unit}}</td>
														<td>{{item.subtotal_price}}</td>
													</tr>


												</tbody>
											</table>
										</div>
										<!-- /.col -->
									</div>
									<!-- /.row -->

									<div class="row">
										<!-- accepted payments column -->
										<div class="col-6">
											
											<!-- <h3 v-if="outlet_level==1" class="d-none d-lg-block"><span
													class='badge badge-pill badge-primary'>{{saleorder.order_type==1?'窗口':'自助'}}</span>
											</h3>
											<h3 v-if="outlet_level==0" class="d-none d-lg-block"><span
													class='badge badge-pill badge-primary'>{{saleorder.order_type==1?'到店':'外卖'}}</span>
											</h3> -->
											
											<div v-if="saleorder.order_type==5" class="pl-2">
												<div class="s-18"><i class="icon-map-marker"></i> 送餐地址</div>
												<div>
													<!-- {"name":"王杰","title":1,"phone_number":"18866668888","address":"4楼仝心科技","is_default":1,"location":{"latitude":32.92669,"longitude":117.37343,"address":"安徽省蚌埠市蚌山区汉明街","name":"横店电影城(宝龙店)"}} -->
													<div class="font-weight-bold"><span
															class="text-muted">{{addressInfo.location!=undefined && addressInfo.location.name!=undefined?addressInfo.location.name:''}}</span>
														{{addressInfo.address}}</div>
													<div>{{addressInfo.name}} {{addressInfo.phone_number}}</div>
												</div>
											</div>
											
										</div>
										<!-- /.col -->
										<div class="col-6">
											
											<p class="lead">应收金额（{{saleorder.pay_status==1?saleorder.paymentDesc:'未支付'}}）</p>

											<div class="table-responsive">
												<table class="table table-sm">
													<tbody>
														<tr>
															<th>商品金额（元）:</th>
															<td>{{saleorder.total_price}} </td>
														</tr>
														<tr>
															<th>打包费（元）:</th>
															<td>{{saleorder.packing_charges}} </td>
														</tr>
														<tr>
															<th>配送费（元）:</th>
															<td>{{saleorder.freight}} </td>
														</tr>
														<tr>
															<th>合计（元）:</th>
															<td class="s-18">{{saleorder.total_amount}} </td>
														</tr>
														<tr v-if="saleorder.refund_amount>0">
															<th>退款（元）:</th>
															<td class="s-18">
																 {{saleorder.refund_amount}}
																 <span v-if="saleorder.refund_status==1" class="s-14 text-warning">(退款中)</span>
															 </td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<!-- /.col -->
									</div>
									<!-- /.row -->

									<!-- this row will not appear when printing -->
									<!-- <div class="row no-print">
					                    <div class="col-12">
					                        <a href="invoice-print.html" target="_blank" class="btn btn-lg  btn-default"><i class="icon icon-print"></i> Print</a>
					                        <button type="button" class="btn btn-success btn-lg  float-right"><i class="icon icon-credit-card"></i> Submit Payment
					                        </button>
					                        <button type="button" class="btn btn-primary btn-lg float-right mr-2">
					                            <i class="icon icon-cloud-download"></i> Generate PDF
					                        </button>
					                    </div>
					                </div> -->

								</div>

							</div>
						</div>

					</div>
					<div class="modal-footer">
						<!-- {{saleorder.pay_status}}/
						{{saleorder.payment}}/
						{{saleorder.refund_status}} -->
						<button v-if="saleorder.pay_status==1 && saleorder.payment=='BALANCE' && saleorder.refund_status==0 "
						 @click="refundOrder"
							:disabled="disabled" type="button" class="btn btn-primary">
							 消费卡退款
						</button>

						<button v-if="outlet_level==0 && saleorder.pay_status==1" @click="printOrder"
							:disabled="disabled" type="button" class="btn btn-primary"><i class="icon icon-print"></i>
							打印点餐单({{saleorder.print_flag==0?'未打印':'已打印'+saleorder.print_flag+'次'}})
						</button>

						<button type="button" class="btn btn-secondary" data-dismiss="modal">关闭</button>
					</div>
				</div>
			</div>
		</div>
		<!-- //订单详情 modal -->

		<!--  配送 modal -->
		<div class="modal fade" id="dModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">配送</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">
							<div class="form-group">
								<div><i class="icon-map-marker"></i> 送餐地址</div>
								<div>
									<!-- {"name":"王杰","title":1,"phone_number":"18866668888","address":"4楼仝心科技","is_default":1,"location":{"latitude":32.92669,"longitude":117.37343,"address":"安徽省蚌埠市蚌山区汉明街","name":"横店电影城(宝龙店)"}} -->
									<div class="font-weight-bold"><span
											class="text-muted">{{addressInfo.location!=undefined && addressInfo.location.name!=undefined?addressInfo.location.name:''}}</span>
										{{addressInfo.address}}</div>
									<div>{{addressInfo.name}} {{addressInfo.phone_number}}</div>
								</div>
							</div>
							<hr>
							<div class="form-group">
								<label>配送员</label>
								<input v-model="carrierInfo.name" class="form-control form-control-lg"
									placeholder="输入配送员姓名" type="text" :readonly="saleorder.trade_status > 1 ? 'readonly' : false">
							</div>

							<div class="form-group">
								<label>配送员电话</label>
								<input v-model="carrierInfo.phone_number" class="form-control form-control-lg"
									placeholder="输入配送员联系电话" type="number" :readonly="saleorder.trade_status > 1 ? 'readonly' : false">
							</div>

						</div>

					</div>
					<div class="modal-footer">
						
						<button 
						v-if="saleorder.trade_status == 1 "
						@click.stop="doDelivery(2)" :disabled="disabled" 
						type="button" class="btn btn-primary"> 确定送出
						</button>
						
						<button
						v-if="saleorder.trade_status == 2 "
						@click.stop="doDelivery(3)" :disabled="disabled" 
						type="button" class="btn btn-info"> 确定送达
						</button>
						
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						
					</div>
				</div>
			</div>
		</div>
		<!-- //配送 modal -->


	</div>
</template>

<script>
	const __payment = {
		'CASH': '现金',
		'CARD': '刷卡',
		'COUPON': "券",
		'BALANCE': '会员卡',
		'WEIXIN': '微信支付',
		'ALIPAY': '支付宝',
		'COMP': '组合付款'
	}

	const __tradeStatus5 = {
		'-1': '已取消',
		'0': '待付款',
		'1': '待配送',
		'2': '已送出',
		'3': "已送达",
		'4': '',
		'5': '',
		'6': '已结单'
	}
	const __tradeStatus1 = {
		'-1': '已取消',
		'0': '待付款',
		'1': '已完成'
	}
	const __payStatus = {
		'-1': '已取消',
		'0': '未付款',
		'1': '已付款'
	}

	import VPagination from '../components/my-page.vue'
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/zh-cn';
	
	export default {
		data() {
			return {
				title: '消费订单列表',

				token: '',
				outlet_id: 0,
				outlet_name: '',
				user_name: '',
				outlet_level: 0,

				setting: {},
				outletlist: [],

				query: '',
				group_id: 0,

				current_page: 1,
				page_size: 50,
				count: 0,
				total_page: 0,
				show_from: 0,
				show_to: 0,

				total: 0, // 记录总条数
				display: 10, // 每页显示条数
				current: 1, // 当前的页数

				selectedIndex: -1,
				saleorder: {}, //选中的对象
				saleorderitemlist: [],

				//////
				start_date: '',
				end_date: '',
				payment: '',
				employee_id: 0,
				order_type: 0,
				pay_status: -9,
				trade_status: -9,

				employeelist: [],
				saleorderlist: [],

				subtotalAmount: 0,
				totalAmount: 0,

				//以下扫码点餐
				carrierInfo: {
					name: '',
					phone_number: '',
				},
				addressInfo: {},
				disabled: false,
			}

		},
		components: {
			VPagination,
			DatePicker
		},
		
		computed: {
			activeEmployees: function () {
				let self = this;
				return this.employeelist.filter(function (item) {
					return item.outlet_id == self.outlet_id;//返回本店的
				})
			}
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			console.log(lsLoginData.logined_outlet_name)
			// self.outlet_id = lsLoginData.logined_outlet_id;
			console.info('--- this.$store.state ->', this.$store.state)
			self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.outlet_name = lsLoginData.logined_outlet_name;
			self.user_name = lsLoginData.user_account;
			self.outlet_level = lsLoginData.outlet_level;

			self.setting = JSON.parse(localStorage.getItem('SETTING'));

			self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
			console.log('--- outlet list ->', self.outletlist)
		},

		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//先取预载数据，再取业务数据
			self.init(() => {
				self.initData();
			})
		},
		methods: {

			init(callback) {
				let self = this;

				//取得员工列表
				self.axios.get(self.GLOBAL.baseURI + 'getAllEmployeeList', {
						params: {
							token: self.token,
							// outlet_id: self.outlet_id,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.employeelist = data.data;

							if (typeof callback == 'function') {
								callback()
							}

						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END

			},

			initData() {
				let self = this;

				//取得员工列表
				self.axios.get(self.GLOBAL.baseURI + 'getMSaleOrderList', {
						params: {
							token: self.token,
							outlet_id: self.outlet_id,
							employee_id: self.employee_id,
							order_type: self.order_type,
							payment: self.payment,
							pay_status: self.pay_status,
							query: self.query,
							start_date: self.start_date,
							end_date: self.end_date,
							current_page: self.current_page,
							page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {

							// self.saleorderlist = data.data.list;
							self.subtotalAmount = data.data.subtotalAmount;
							self.totalAmount = data.data.totalAmount;
							self.total = data.data.totalCount;

							self.start_date = data.data.start_date;
							self.end_date = data.data.end_date;

							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)

							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;

							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数

							self.saleorderlist = []; //重置
							for (let order of data.data.list) {
								order.paymentDesc = __payment[order.payment];
								order.payStatusDesc = __payStatus[order.pay_status]
								order.tradeStatusDesc = order.order_type == 5 ? __tradeStatus5[order
									.trade_status] : __tradeStatus1[order.trade_status]
								self.saleorderlist.push(order);
							}
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END

			},

			pagechange: function(currentPage) {
				console.log(currentPage);
				// ajax请求, 向后台发送 currentPage, 来获取对应的数据
				this.current_page = currentPage;
				this.initData();
			},

			search() {
				let self = this;

				self.initData();
			},

			view(index) {
				console.log(index);
				let self = this;

				let saleorder = self.saleorderlist[index];
				console.log(index, saleorder);

				self.selectedIndex = index;
				self.saleorder = saleorder;
				
				if (saleorder.order_type == 5 && saleorder.address != '' && saleorder.address.indexOf('{') != -1) {
					self.addressInfo = JSON.parse(saleorder.address);
					console.log('--- addressInfo->', self.addressInfo)
				}

				self.getSaleOrder(() => {
					$('#hModal').modal('show');
				});
			},

			getSaleOrder(callback) {
				let self = this;
				console.log(self.saleorder);

				if (!self.saleorder.id)
					return;

				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,
					order_id: self.saleorder.id,
				}

				self.axios.get(self.GLOBAL.baseURI + 'getMSaleOrderDetails', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						if (data.code == 200) {
							self.saleorderitemlist = data.data.pre_sale_order.item;
							if (typeof callback == 'function') {
								callback()
							}
						} else {
							alert(data.message)
						}
					});
			},

			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init(() => {
					self.initData();
					self.employee_id = 0;
				})

			},

			//以下扫码点餐
			deliveryReady(index) {
				console.log(index);
				let self = this;

				let saleorder = self.saleorderlist[index];
				console.log(index, saleorder);

				self.selectedIndex = index;
				self.saleorder = saleorder;

				if (saleorder.address != '' && saleorder.address.indexOf('{') != -1) {
					self.addressInfo = JSON.parse(saleorder.address);
					console.log('--- addressInfo->', self.addressInfo)
				}
				
				if(saleorder.trade_status == 2){
					self.carrierInfo.name = saleorder.carrier;
					self.carrierInfo.phone_number = saleorder.carrier_phone_number;
					console.log('--- carrierInfo->', self.carrierInfo)
				}


				$('#dModal').modal('show');

			},

			doDelivery(trade_status) {
				console.log('--- trade_status ->', trade_status)
				let self = this;

				let saleorder = self.saleorderlist[self.selectedIndex];
				console.log(self.selectedIndex, saleorder);

				let carrierInfo = self.carrierInfo;
				
				if (!self.carrierInfo.name) {
					// alert('配送员姓名不能为空')
					self.$toasted.error( '配送员姓名不能为空', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if (!self.carrierInfo.phone_number) {
					// alert('配送员电话不能为空')
					self.$toasted.error( '配送员电话不能为空', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}

				self.disabled = true;

				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,
					order_id: self.saleorder.id,
					// trade_status: 2, //2表示送出
					trade_status: trade_status, //2表示送出
					carrier: self.carrierInfo.name,
					tracking_no: self.carrierInfo.phone_number,
				}

				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'updatePreSaleOrder', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;

						if (data.code == 200) {
							//配送成功
							self.saleorderlist[self.selectedIndex]["trade_status"] = trade_status;
							self.saleorderlist[self.selectedIndex]["tradeStatusDesc"] = __tradeStatus5[trade_status];
							
							saleorder.carrier = self.carrierInfo.name;
							saleorder.carrier_phone_number = self.carrierInfo.phone_number;

							$('#dModal').modal('hide');
						} else if (data.code == 8302) {
							// alert('找不到订单')
							self.$toasted.error( '找不到订单', {
								position: 'top-center',
								duration: '2000',
							})
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},

			printOrder() {
				let self = this;

				let saleorder = self.saleorderlist[self.selectedIndex];
				console.log(self.selectedIndex, saleorder);

				let carrierInfo = self.carrierInfo;

				self.disabled = true;

				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,
					order_id: self.saleorder.id,
				}

				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'reprintRecciept', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data,data.data.result_code);
						self.disabled = false;

						if (data.code == 200) {
							if (data.data.result_code == 'SUCCESS') {
								self.saleorder.print_flag = self.saleorder.print_flag+1;
								$('#hModal').modal('hide');
								// alert('打印成功')
								self.$toasted.success( '打印成功', {
									position: 'top-center',
									duration: '2000',
								})
							} else {
								// alert(data.data.result_msg)
								// alert('打印失败')
								self.$toasted.error( '打印失败', {
									position: 'top-center',
									duration: '2000',
								})
							}

						} else if (data.code == 8302) {
							// alert('找不到订单')
							self.$toasted.error( '找不到订单', {
								position: 'top-center',
								duration: '2000',
							})
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END

			},
			
			//消费卡退款
			refundOrder(){
                let self = this;
                
                let saleorder = self.saleorderlist[self.selectedIndex];
                console.log(self.selectedIndex, saleorder);
                
                self.disabled = true;

                // 组装数据
                let postData = {
                    token: self.token,
                    outlet_id: self.outlet_id,
                    order_id: saleorder.id,
                }

                let postData2 = new FormData();
                for (var key in postData) {
                    console.log(key + ':' + postData[key]);
                    postData2.append(key, postData[key]);
                }


                //调用接口
                this.axios.post(this.GLOBAL.baseURI + 'refundMSaleOrder',
                        postData2
                    )
                    .then(function(response) {
                        console.log(response);
                        return response.data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    })
                    .then(function(data) {
                        // always executed
                        console.log(data.data);

                        self.disabled = false;

                        if (data.code == 200) {
							if(data.data.result_code == 'success'){//业务成功
								self.$toasted.success('退款成功', {
									position: 'top-center',
								}).goAway(2000)
								$('#hModal').modal('hide');
								
								//UI刷新
								saleorder.refund_amount = saleorder.total_amount;
								saleorder.refund_status = 2;
								saleorder.total_amount = 0;
								
								self.$set(self.saleorderlist, self.selectedIndex, saleorder);
							}
							else{//业务失败
								self.$toasted.error(data.data.result_msg, {
									position: 'top-center',
								}).goAway(2000)
							}
							
                        } else if (data.code == 101) {
                            //登录超时
							 $('#hModal').modal('hide');
                            self.logoff();
                        } else {
                            alert(data.message)
                        }
                    });
                //END
			},
		},
	}
</script>

<style>
	.mx-icon-calendar,
	.mx-icon-clear {
		right: 28px !important;
	}
</style>
